:host {
  --color-bg-default: #FFF;
  --color-fg-primary: #A82020;
  --color-fg-default: #1D1C1A;
  --color-fg-accent: #A82020;
  --color-bg-inrange: #EFEBE5;
  --day-width: 35px;
  --day-height: 30px;
}

.calendar {
  > .header {
    button:focus-visible {
      outline-color: $color-ludwell-black;
    }
  }

  > .days-grid {
    > .day:focus-visible {
      outline-color: $color-ludwell-black;
    }
  }
}
